import SudokuSolverVisualizer from "./sudoku/SudokuSolverVisualizer";
import "./App.css";

function App() {
  return (
    <>
      <div className="header">
      <h1>Sudoku Solver By The 
        <a href="https://krishnakush.tech">
          <u> Krishna Kushwaha</u>
        </a>
      </h1>
      </div>
      <div className="main">
        <SudokuSolverVisualizer />
      </div>
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  );
}

export default App;
